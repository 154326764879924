.permissions-accordion {
  &__button {
    padding: 12px 24px;
    border-bottom: 1px solid #eee;

    &:hover {
      cursor: pointer;
    }
  }

  &__iconBorder {
    padding: 12px 0 12px 16px;
    margin-left: 12px;
    border-left: 1px solid #eee;
  }

  &__panel {
    padding: 8px 30px;
    animation: fadein 0.2s ease-in;
    border-bottom: 1px solid #eee;

    @media (max-width: 768px) {
      padding: 8px 8px;
    }
  }

  &__submit {
    padding: 4px 24px 16px 24px;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

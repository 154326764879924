$primary: rgb(21, 47, 204);
$secondary: rgb(219, 149, 35);
$complementary-green: #169c85;
$complementary-blue: #026cd6;
$complementary-yellow: #fffd82;
$highlight: rgb(253, 243, 224);
$chat-highlight: #add1f3;
$ticket-comment-highlight: #add1f330;
$chat-online: rgb(134, 219, 11);
$chat-idle: rgb(255, 167, 0);
$hidden: #fffbe6;

$success: #389e0d;
$successAlert: rgb(219, 149, 35);
$error: crimson;
$light-success: #f6ffed;
$light-danger: rgb(242, 222, 222);
$light-info: #ade1e624;

$gray-900: rgb(17, 24, 39);
$gray-800: rgb(31, 41, 55);
$gray-700: rgb(55, 65, 81);
$gray-600: rgb(75, 85, 99);
$gray-500: rgb(107, 114, 128);
$gray-400: rgb(156, 163, 175);
$gray-300: rgb(209, 213, 219);
$gray-200: rgb(229, 231, 235);
$gray-100: rgb(243, 244, 246);

.user-timer {
  &__link {
    color: #666;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: rgba($primary, 0.05);
    }
    @media (max-width: 768px) {
      width: 35px;
    }
  }

  &__badge {
    &--icon {
      color: white;
      font-size: 14px;
      background: #ff4d4f;
      border-radius: 50%;

      svg {
        position: relative;
        left: 1px;
      }
    }
  }
}

.timer-dropdown {
  min-width: 320px;
  max-width: 320px;

  .timer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #eee;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0;
        font-weight: 500;
      }
    }

    &__icon {
      font-size: 14px;
      margin-right: 8px;
    }

    &__link {
      font-size: 12px;
    }
  }

  &__timer-list {
  }

  .timer-list {
    list-style-type: none;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;

    .item {
      padding: 15px;
      border-bottom: 1px solid #efefef;
      display: flex;
      justify-content: space-between;

      .container {
        max-width: 70%;
      }

      &__text {
        margin: 0;
        overflow-wrap: break-word;

        span {
          overflow-wrap: break-word;
        }
      }

      &__date {
        date: 0;
        color: #aaa;
      }
    }

    &--latest {
      max-height: 200px;

      .item {
        &__text {
          color: #aaa;
        }
      }
    }
  }
}

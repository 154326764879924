.ticket-history {
  &__row {
    font-size: 12px;
    color: #666 !important;
    //border-bottom: 1px solid $gray-100;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 4px;
  }

  &__entry {
    padding: 4px;
    &--hidden {
      background: $hidden;
      padding: 8px 15px;
    }
  }

  &__icon {
    font-size: 14px;
    color: #faad14;
    margin-right: 8px;
  }

  &__link {
    color: $primary !important;
    cursor: pointer;
  }

  &__value {
    &--new {
      color: green;
    }

    &--old {
      color: red;
    }
  }

  &__audio-player {
    margin-top: 8px;
    max-width: 250px;
  }
}

.worktime-filter {
  width: 100%;
  margin-bottom: 0;

  &__spinner {
    padding: 30px;
  }
  &__select-all {
    width: 100%;
    padding: 0px 20px 20px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  &__scrollable {
    max-height: calc(100vh - 212px);
    overflow-y: auto;
    padding: 20px;
    display: block;
  }
  &__options {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 20px 20px 0px 20px;
  }
}

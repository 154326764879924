.filter-drawer {
  .ant-drawer-body {
    padding: 20px 0;
  }

  &--p20 {
    .ant-drawer-body {
      padding: 20px;
    }
  }
}

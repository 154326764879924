.jb-link {
  color: #000c17;
  overflow-wrap: break-word;
  &--block {
    display: block;
  }

  &:active,
  :hover,
  :visited {
    overflow-wrap: break-word;
  }
}

.dnd-ticket-group {
  padding: 10px 12px;
  border-bottom: 1px solid #eee;
  background-color: white;
  border-radius: 0px;
  transition: background-color linear 0.2s, border-radius linear 0.2s;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &--dragging {
    background-color: $secondary;
    color: white;
    border-radius: 5px;
  }

  &__actions {
    margin-left: auto;
  }
}

.user-edit-page {
  .memberships {
    &__name {
      margin-bottom: 14px;

      @media (max-width: 767px) {
        font-weight: bold;
      }
    }
    &__label {
      margin-bottom: 8px;
      padding-bottom: 8px;
      font-weight: 600;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .show-label {
      .ant-form-item-label {
        display: block;
      }
    }
    .ant-form-item-label {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }
  }
}

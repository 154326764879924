.home-splitter {
  padding: 5px 0px;
  &__tasks {
    background-color: white;
    transition: background-color linear 0.2s, border-radius linear 0.2s;
    &--dragging-over {
      background-color: rgba($primary, 0.05);
    }
  }

  &__title-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 8px;
  }
  &__title {
    margin: 0 0 0 12px;
    font-weight: 500;
  }

  &__ticket {
  }

  .ticket {
    &__title {
      overflow-wrap: break-word;
    }
  }
  &__avatar {
  }

  &__handle {
    margin-left: 8px;
  }

  &__card {
    transition: background-color linear 0.2s, border-radius linear 0.2s;
    &--hidden-body {
      .ant-card-body {
        padding: 0;
      }
    }
    &--dragging {
      opacity: 0.95;
      border-radius: 10px;
    }
  }
}

.home-splitters-container {
  background-color: white;
  transition: background-color ease-in-out 0.2s;
  &--dragging-over {
    background-color: rgba($primary, 0.05);
  }
}

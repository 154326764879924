.worktime {
  &__table {
    .ant-table-body {
      overflow-y: auto !important;
    }
  }

  &__row {
    &--expandable {
      > .ant-table-cell {
        //padding: 0;
      }

      .ant-table {
        margin: 0 !important;
      }
    }

    &--fulfilled {
      .worktime__column--balance {
        color: green;
      }
    }

    &--unfulfilled {
      .worktime__column--balance {
        color: red;
      }
    }
  }
}

.users-worktimes {
  &__filters {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__calendar {
    @media (max-width: 1188px) {
      width: calc(50% - 5px);
      .ant-btn {
        width: 100% !important;
      }
    }

    @media (max-width: 655px) {
      width: 100%;
      .ant-btn {
        width: 100% !important;
      }
    }
  }

  &__status {
    min-width: 110px;
    @media (max-width: 1188px) {
      width: calc(50% - 5px);
      min-width: calc(50% - 5px);
    }

    @media (max-width: 655px) {
      width: 100%;
      min-width: 100%;
    }
    .ant-select {
      @media (max-width: 1188px) {
        width: 100%;
        min-width: 50%;
      }

      @media (max-width: 655px) {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  &__users {
    width: calc(50% - 175px - 55px);
    min-width: 320px;

    @media (max-width: 1268px) {
      width: calc(50% - 175px - 55px);
      min-width: 280px;
    }

    @media (max-width: 1188px) {
      width: calc(50% - 5px);
    }
    @media (max-width: 655px) {
      width: 100%;
      min-width: 100%;
    }
  }

  &__groups {
    width: calc(50% - 175px - 55px);
    min-width: 320px;
    @media (max-width: 1268px) {
      width: calc(50% - 175px - 55px);
      min-width: 280px;
    }
    @media (max-width: 1188px) {
      width: calc(50% - 5px);
    }
    @media (max-width: 655px) {
      width: 100%;
      min-width: 100%;
    }
  }

  &__submit {
    margin-top: 30px;
    margin-left: auto;
  }
}

.jb-button {
  color: #000c17;

  &--ghost {
    display: block;

    &.jb-button {
      &--success {
        border-color: $success;
        color: $success;
      }

      &--secondary {
        border-color: $secondary;
        color: $secondary;
      }
    }
  }
}

.single-month {
  margin-bottom: 16px;

  .ant-table-title {
    padding: 0 !important;
  }

  &__title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    background: #efefef;
    padding: 10px;
    margin-bottom: 16px;
  }

  &__column {
    &--title {
      min-width: 200px;
    }

    &--cost {
      min-width: 100px;
    }

    &--users {
      min-width: 150px;
    }

    &--hour-cost {
      min-width: 80px;
    }
  }

  &__summary {
    &--month {
      //background: $light-success;
      background: #efefef;
    }
    &--total {
      //background: $light-danger;
      background: #efefef;
    }
  }
}

.company-picker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow: auto;
  width: 300px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: calc(33% - 8px);
    padding: 8px 0;
    margin: 2px 4px;

    &--active {
      background-color: #f0f4ff;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}

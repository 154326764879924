.statistics-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__icon {
    font-size: 40px;
    margin-right: 32px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &__title {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: $gray-400;
  }

  &__value {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
}

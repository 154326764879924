.ticket-group-page {
  &__table {
  }

  .table {
    &__row {
      &--highlight {
        background: $highlight;
        .ant-table-cell-fix-right {
          background: $highlight;
        }
      }
    }

    &__ticket-info {
      div {
        margin: 0 !important;
      }

      &--flex {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__column {
      &--type {
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }
      &--title {
      }
      &--priority {
        min-width: 170px;
      }
      &--progress {
        min-width: 90px;
      }
      &--assigned {
        min-width: 180px;
      }
      &--updated {
        min-width: 170px;
      }
    }
  }
}

.invitation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__image {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 16px;
  }

  &__text {
  }
}

.attachments-table {
  &__row {
    &--hidden {
      background: $hidden;
    }
    &--actions {
      &.ant-table-cell {
        display: table-cell;
        justify-content: flex-end;
      }
    }
  }
}

.calendar-page {
  &__table {
  }

  .table {
    &__row {
      &--highlight {
        background: $highlight;
        .ant-table-cell-fix-right {
          background: $highlight;
        }
      }
    }
  }
}

.user-notifications {
  &__link {
    color: #666;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: rgba($primary, 0.05);
    }

    @media (max-width: 768px) {
      width: 35px;
    }

    @media (max-width: 350px) {
      width: 30px;
    }
  }
}

.notifications-dropdown {
  min-width: 320px;
  max-width: 320px;

  .notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #eee;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        font-weight: 500;
      }
    }

    &__icon {
      font-size: 14px;
      margin-right: 8px;
    }

    &__link {
      font-size: 12px;
    }
  }

  &__notifications-list {
  }

  .notifications-list {
    list-style-type: none;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;

    &__item {
    }
    .item {
      padding: 10px 15px;
      border-bottom: 1px solid #efefef;

      &__link {
        overflow-wrap: break-word;
      }
      &__text {
        margin: 0;
        overflow-wrap: break-word;
        font-size: 12px;
      }

      &__date {
        margin: 0;
        color: #aaa;
        font-size: 12px;
        margin-top: 3px;
      }

      &--unread {
        background: $highlight;
      }
    }
  }

  .notifications-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 6px;
    border-top: 1px solid #eee;
    &__link {
      font-size: 12px;
    }
  }
}

.user-app {
  &__avatar {
    &:hover {
      cursor: pointer;
    }
    &--user-list {
      margin-right: 12px !important;
    }
  }
}

.user-dropdown {
  .user-info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;

    &__container {
      margin: 0 16px;
    }

    &__version {
      margin: 0 12px;
      color: rgba(0, 0, 0, 0.45);
    }

    &__name {
      margin: 0;
      font-weight: bold;
    }

    &__position {
      margin: 0;
      color: rgba(0, 0, 0, 0.45);
      max-width: 150px;
    }

    &__email {
      margin: 0;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

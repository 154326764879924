.comment-editor {


  &__input-microphone-icon > svg {
    color: gray;
  }

  &__input-microphone-icon--stop > svg {
    color: red;
  }



}
.chat {
  &-page {
    @media (max-width: 768px) {
    }
  }

  &__message {
    margin-bottom: 8px;
    -webkit-transform: translate3d(0, 0, 0);
    &--self {
    }
  }

  &__bubble-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__bubble-wrapper {
    padding-right: 45px;
    position: relative;

    &:hover {
      .chat__bubble-options {
        display: flex;
      }
    }

    .ant-popover {
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        border-radius: 100%;
      }
      .ant-popover-content,
      .ant-popover-inner-content {
        padding: 0 !important;
      }
    }
  }

  &__bubble-options {
    display: none;
    position: absolute;
    right: 15px;
    top: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &__bubble {
    background: $gray-200;
    color: black;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 4px;
    position: relative;
    overflow-wrap: break-word;
    -webkit-transform: translate3d(0, 0, 0);
    display: flex;
    flex-direction: column;

    &--with-reactions {
      margin-bottom: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 25px;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-right-color: $gray-200;
      border-left: 0;
      border-top: 0;
      margin-top: -12.5px;
      margin-left: 0px;
    }

    &--self {
      background: $secondary;
      color: white;

      &::before {
        border-right-color: $secondary;
      }

      .chat__date {
        color: white !important;
      }
    }

    &--linked {
      background: $chat-highlight;
      color: white;

      &::before {
        border-right-color: $chat-highlight;
      }

      .chat__date {
        color: black !important;
      }
    }
  }

  &__author {
    display: inline-block;
    padding-bottom: 4px;
    margin-left: 5px;
  }

  &__date {
    //display: flex;
    //justify-content: flex-end;
    //margin: 4px 0 0 0;
    //margin: 4px 0 0 0;
    color: $gray-600;

    //position: absolute;
    //right: 8px;
    //top: 4px;
  }

  &__list {
    // 100vh - header height - input-box height - card paddings -  page padding - breadcrumbs
    height: calc(100vh - 65px - 78px - 24px - 50px - 50px);

    @media (max-width: 991px) {
      height: calc(100vh - 65px - 78px - 24px - 50px - 50px - 98px - 8px);
    }

    @media (max-width: 768px) {
      // 100vh - header height - input-box height - card paddings -  page padding - users
      height: calc(100vh - 65px - 78px - 24px - 8px - 50px - 8px);
    }

    &--ios {
      @media (max-width: 768px) {
        // 100vh - header height - input-box height - card paddings -  page padding - users
        height: calc(100vh - 65px - 78px - 24px - 90px - 50px - 8px);
      }
    }

    &--ios-installed {
      @media (max-width: 768px) {
        // 100vh - header height - input-box height - card paddings -  page padding - users
        height: calc(100vh - 65px - 78px - 24px - 30px - 50px - 8px);
      }
    }

    &--android {
      @media (max-width: 768px) {
        // 100vh - header height - input-box height - card paddings -  page padding - users
        height: calc(100vh - 65px - 78px - 24px - 60px - 50px - 8px);
      }
    }
    background: linear-gradient(transparent, transparent) fixed;
    overflow: auto;
  }

  &__rooms {
    // height: calc(100vh - 65px - 78px - 24px - 48px - 8px);
    height: calc(100vh - 65px - 78px - 24px - 50px);
    overflow: auto;
  }

  &__room-btn {
    height: auto;
  }
  &__room-btn-content {
    height: auto;
    padding: 0;
  }

  &__reactions {
    position: relative;
    padding: 0;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: -22px;
    right: -10px;
    border-radius: 5px;
    display: flex;
  }

  &__reactions-count {
    color: black;
    font-size: 10px;
    height: 10px;
    width: 10px;
    z-index: 10;
    border-radius: 50%;
    bottom: 2px;
    right: 0;
    position: relative;
  }

  &__reactions-item {
    background: whitesmoke;
    background-blend-mode: color-burn;
    font-size: 14px;
    padding: 0;
    width: 19px !important;
    height: 19px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    border: 0;
    box-shadow: none;
    cursor: pointer;

    &--multiple {
      width: 35px !important;
      border-radius: 12px !important;
    }

    div {
      height: auto !important;
      width: auto !important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      font-size: 16px;
      height: auto;
      width: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__users {
    height: calc(100vh - 65px - 78px - 24px - 50px);
    overflow: auto;
    width: 100%;

    @media (max-width: 991px) {
      width: auto;
      height: 34px;
      display: flex !important;
      overflow-y: hidden;
      overflow-x: auto;
      margin-right: auto;
    }
  }
  &__user {
    padding: 8px;
    @media (max-width: 991px) {
      min-width: 40px !important;
      max-width: 70px;
      display: flex !important;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
      gap: 0px !important;
    }
  }

  &__upload-list {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  &__upload-list__file {
    display: flex;
    max-width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid gray;
  }

  &__images {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  &__image {
    border-radius: 10px;
    max-width: 300px;
    height: auto;
    padding: 5px;
    width: 100%;
    cursor: pointer;
  }

  &__audio-player {
    margin-top: 8px;
    max-width: 200px;
  }


  .user {
    &__avatar {
      margin-right: 4px;

      @media (max-width: 991px) {
        margin-right: auto;
      }

      &--online {
        box-shadow: 0 0 0 2px white, 0 0 0 4px $chat-online;
      }
      &--idle {
        box-shadow: 0 0 0 2px white, 0 0 0 4px $chat-idle;
      }
    }
  }

  &__name {
    margin: 0;
    @media (max-width: 991px) {
      line-height: 1em;
      font-size: 0.8em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      min-height: 30px;
      line-height: 1.2em; /* fallback */
      max-height: 30px; /* fallback */
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      margin-top: 8px;
      text-align: center;
      display: none;
    }
  }

  &__status {
    margin: 0;
    color: #999;
    @media (max-width: 991px) {
      display: none;
    }

    &--active {
      color: #fefefe;
    }
  }

  &__room-name {
    margin: 0;
  }

  &__room-status {
    margin: 0;
    color: #999;

    &--active {
      color: #fafafa;
    }
  }

  &__input-emoji {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }



  &__input-emoji-icon > svg {
    color: gray;
    font-size: 20px;
  }
  &__input-microphone {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input-microphone-icon > svg {
    color: gray;
    font-size: 20px;
  }

  &__input-microphone-icon--stop > svg {
    color: red;
    font-size: 20px;
  }

  &__input-container {
    width: 100%;
    margin-top: auto;
    z-index: 1102;
    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      background: white;
      margin: 0 !important;
      left: 0;
    }
  }

  &__scroll-to-bottom {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;

    @media (max-width: 768px) {
      position: absolute;
      z-index: 2;
      bottom: 35px;
    }
  }

  &__archive-spinner {
    color: $primary;
    font-size: 24px;
  }

  &__archive-spinner-container {
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__preload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &-spinner {
      color: $primary;
      font-size: 48px;
      padding: 20px;
    }
  }

  &__input {
    background: $gray-100;
    border: 0 !important;
    border-radius: 10px;
    max-height: 100px !important;
    padding-right: 25px !important;

    @media (max-width: 768px) {
      max-height: 100px !important;
    }

    .mentions__input {
      border: 0 !important;
      border-radius: 10px;
      max-height: 100px !important;
      overflow: auto;
      padding-right: 50px !important; //25px

      @media (max-width: 768px) {
        max-height: 100px !important;
        margin: 1px 0 0 0 !important;
      }
    }
  }
}

.tooltip-9999-z-index {
  .ant-tooltip {
    z-index: 9999 !important;
  }
}
.mentions {
  margin: 1em 0;
}

.mentions--multiLine .mentions__highlighter {
  border: 0 !important;
  padding: 9px;
  max-height: 100px !important;
  margin: 1px 0 0 0;
  @media (max-width: 768px) {
    max-height: 100px !important;
  }
}

.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
  overflow: auto !important;
  max-height: 100px !important;

  @media (max-width: 768px) {
    max-height: 100px !important;
  }
}
.mentions__suggestions {
  max-width: 100%;
}
.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  max-height: calc(300px);
  overflow-y: scroll;
}

.mentions__suggestions__item {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 100%;
  overflow-wrap: break-word;
}

.mentions__suggestions__item--focused {
  background-color: $primary;
  color: white;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: $primary;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
  margin: 1px 0 0 0 !important;
  border: 0 !important;
}

.ticket-page {
  .ticket-info {
    &__label-col {
      @media (max-width: 768px) {
        padding-top: 5px;
      }
    }
    &__label {
      line-height: 32px;

      @media (max-width: 768px) {
        line-height: 22px;
      }
    }

    &--pay {
      color: $success;
      font-weight: 500;
    }
  }
}
